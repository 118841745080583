import React, { useEffect } from "react"

const ContactForm = () => {

  let params;
  let isSend;

  useEffect(() => {
    if(!window) return;
    params = (new URL(window.document.location)).searchParams;
    isSend = (params.get("send") === "true");
  },[]);



  return (
    <>

      {isSend && <div id="thankyou">
        THanks for sending stuff !
      </div>
      }

    <form id="contactform" method="POST" action="http://mailer.flexelektro.de">
      <label>
        Name
        <input type="text" name="name" id="name" />
      </label>
      <label>
        Email
        <input type="email" name="email" id="email" />
      </label>
      <label>
        Subject
        <input type="text" name="subject" id="subject" />
      </label>
      <label>
        Message
        <textarea rows={10} name="message" id="message" />
      </label>

      <button className={"btn"} type="submit">Send</button>

    </form>
      </>
  );
}

export { ContactForm };
