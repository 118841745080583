// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-wp-showcase-js": () => import("./../src/components/templates/wpShowcase.js" /* webpackChunkName: "component---src-components-templates-wp-showcase-js" */),
  "component---src-components-templates-wp-page-js": () => import("./../src/components/templates/wpPage.js" /* webpackChunkName: "component---src-components-templates-wp-page-js" */),
  "component---src-components-templates-wp-post-js": () => import("./../src/components/templates/wpPost.js" /* webpackChunkName: "component---src-components-templates-wp-post-js" */),
  "component---src-components-templates-page-mdx-js": () => import("./../src/components/templates/pageMdx.js" /* webpackChunkName: "component---src-components-templates-page-mdx-js" */)
}

